import { useEffect, useRef, useState } from "react";
import ContentPayWallExclusive from "../ContentPayWallExclusive";
import { Comments, Login } from "./style";
import { SubscribeButton } from "@/shared/SubscribeButton";
import { handleClick } from "../Analitics/GoogleAnalytics4";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppTrue } from "@/hooks/index";

const Viafoura = ({ title = "SUSCRIBITE PARA COMENTAR", idNews }) => {

  const isApp = useAppTrue();

  const elementViafoura = useRef();

  /* Agregamos validadion para el estado "wads" */
  const [wads, setWads] = useState(null); //Inicializamos wads como null
  const { loginWithRedirect } = useAuth0();

  const redirectLogin = async () => {
    handleClick("ui_interaction", "header principal", "Boton Ingresar");
    let expDate = getExpireDate(1);
    document.cookie = `auth0_redir=${
      window.location.href
    };expires=${expDate.toUTCString()};path=/`;
    loginWithRedirect();
  };

  useEffect(() => {
    const data = window.localStorage.getItem("wads_userProfile");
    if (data) {
      setWads(data);
    }
  }, []);
  /* Fin de validacion */
  useEffect(() => {
    const loadViafoura = (entries, observer) => {
      const element = entries[0];
      if (element.isIntersecting) {
        loadComments();
        observer.disconnect();
      }
    };

    const observer = new IntersectionObserver(loadViafoura, {
      rootMargin: "200px",
    });

    observer.observe(elementViafoura.current);

    return () => observer.disconnect();
  }, []);

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      script.src = src;
      document.head.append(script);
    });
  };

  const loadComments = () => {
    loadScript("//cdn.viafoura.net/vf-v2.js")
      .then(async () => {
        window.vf.$prepublish((channel, event, ...args) => {
          const nodoVfCommentsCount =
            document.querySelectorAll(".vf-comments-count");
          if (
            channel === "comments" &&
            event === "read" &&
            !(nodoVfCommentsCount && nodoVfCommentsCount.length > 0)
          ) {
            const nodoNumbComment = document.querySelectorAll(".numb_comment");
            nodoNumbComment.forEach((e) => {
              e.innerHTML = "<span>0</span>";
            });
          }

          if (channel === "authentication" && event === "required") {
            return false;
          } else if (
            (channel === "commenting" && event === "loaded") ||
            (channel === "comments" && event === "read")
          ) {
            //set caja comments

            const nodo_content_editor = document.querySelectorAll(
              ".viafoura .vf-content-editor-input"
            );
            nodo_content_editor.forEach((nodo) => {
              nodo.placeholder =
                "Sumá tu opinión y dejá tu comentario por favor";
            });

            const nodo_load_more = document.querySelector(
              ".viafoura .vf3-comments__bottom-action span.vf-loader-button__content"
            );
            if (nodo_load_more) {
              nodo_load_more.textContent = "Cargar más comentarios";
            }

            return false;
          } else if (channel === "tray" && event === "open-intent") {
            return false;
          } else if (channel == "login" && event == "success") {
            return { channel, event, args };
          } else {
            return { channel, event, args };
          }
        });
      })
      .catch(() => console.error("ERROR CARGANDO VIAFOURA."));
  };

  return (
    <Comments ref={elementViafoura} userData={wads}>
      <hr/>
      <div className="title-comments">
        <div className="comments">
          <p className="titleComments">Comentarios</p>
          {/* Se comenta por el momento hasta tener resuelto el BUG en PROD (https://agea.atlassian.net/browse/SD-2495) */}
          {/* <p>Comentar las notas de Clarín es exclusivo para suscriptores.</p>*/} 
        </div>
        <div id="activateComments" data-target="#mainCommentsDivPase">
          {wads ? (
            <div></div>
          ) : !isApp && (
            <div className="text exclusivo">
              <div className="info">
                <div className="commentSuscribe">
                  <button className="SuscribeButton">
                  <SubscribeButton title={title} />
                  </button>
                  <Login
                    id="btn-login-already"
                    className="button-login"
                    aria-label="Boton Ingresar"
                    onClick={redirectLogin}
                  >
                    <p>YA TENGO SUSCRIPCIÓN</p>
                  </Login>
                </div>
              </div>
              <br />
            </div>
          )}
        </div>
      </div>
      <div className={"viafoura"}>
        <vf-conversations
          vf-container-id={idNews}
          pagination-limit={10}
          limit={2}
        />
      </div>
    </Comments>
  );
};

export default Viafoura;
