import React from 'react'
import { useEffect, useState } from 'react'
import { getDateFormatForNews, getDateFormatZulu } from 'helpers';
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const FormattedDate = (props) => {

  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  // si no tengo props, busco contexto
  if(Object.keys(props).length === 0 && !!formatContent) {
      props = { 
          ...props,          
          publishedDate: formatContent?.storyContainer?.data?.publishedDate,
          modificatedAt: formatContent?.storyContainer?.data?.modificatedAt,
          includeTimeZone: formatContent?.storyContainer?.data?.includeTimeZone,
      };
  } 

  const { publishedDate, modificatedAt, includeTimeZone } = props;

  const [showModificatedAt, setShowModificatedAt] = useState(false)
  const [formattedPublishedDate, setFormattedPublishedDate] = useState('')
  const [formattedModificatedDate, setFormattedModificatedDate] = useState('')
  const formattedPublishedDateZulu = getDateFormatZulu(publishedDate)
  const formattedModificatedDateZulu = getDateFormatZulu(modificatedAt)

  useEffect(() => {
    setFormattedPublishedDate(getDateFormatForNews(publishedDate))
    setShowModificatedAt(publishedDate !== modificatedAt)
    if (showModificatedAt) {
      setFormattedModificatedDate(
        getDateFormatForNews(modificatedAt, includeTimeZone),
      )
    }
  }, [publishedDate, modificatedAt, includeTimeZone, showModificatedAt])

  return (
    <div className="date">
      {!showModificatedAt ? (
        <time
          className="createDate"
          dateTime={formattedPublishedDateZulu}
          suppressHydrationWarning
        >
          {formattedPublishedDate}
        </time>
      ) : (
        <span className="createDate">{formattedPublishedDate}</span>
      )}
      {showModificatedAt && (
        <time
          suppressHydrationWarning
          className="modificatedDate"
          dateTime={formattedModificatedDateZulu}
        >
          / Actualizado al {formattedModificatedDate}
        </time>
      )}
    </div>
  )
}

export default FormattedDate
