import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const Comments = styled.div.attrs((props) => ({
  className: props.className,
}))`
  padding: 2.5rem 0;

  hr {
    margin: 0;
    border: 0 none;
    &:before {
      content: "";
      height: 1px;
      width: 100%;
      background: #d1d1d1;
      display: block;
      margin: 3px 0;
      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          background: ${ColorDark.greySeparator};
        }
      }
    }
    &:after {
      content: "";
      height: 1px;
      width: 100%;
      background: #d1d1d1;
      display: block;
      margin-top: 5px;
      ${breakpoints.darkMode} {
        background: ${ColorDark.greySeparator};
      }
    }
  }
  .comments {
    display: flex;
    flex-direction: column;
    padding: 12px 0 15px 0;
    p {
      ${Font.sansRegular};
      font-size: 17px;
      padding-top: 15px;
      color: ${Color.grey};
      line-height: 21px;

      ${breakpoints.darkMode} {
        [data-theme="dark"] & {
          color: ${ColorDark.textLight};
        }
      }
      ${breakpoints.phone} {
        font-size: 15px;
        line-height: 19px;
        letter-spacing: -0.15px;
      }

      &.titleComments {
        ${Font.sansBold};
        font-size: 16px;
        padding-top: 0;
        color: ${Color.black000};
        line-height: 28px;
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            color: ${ColorDark.textLight};
          }
        }
        ${breakpoints.phone} {
          font-size: 17px;
        }
      }
    }
  }

  .info {
    display: flex;
    align-items: center;

    .commentSuscribe {
      gap: 0.5rem;
      ${Font.sansBold};
      display: flex;
      flex-wrap: wrap;
      ${breakpoints.phone} {
        justify-content: center;
      }

      div {
        margin-right: 1rem;
      }
      .SuscribeButton {
        border: none;
        background: none;
        a {
          background: white;
          border: 1px solid black;
          padding: 0 2rem;
          align-items: center;
          font-size: 12px;
          line-height: 12px;
          width: 100%;
          ${breakpoints.darkMode} {
            [data-theme="dark"] & {
              border: 1px solid ${ColorDark.textLight};
              background: none;
              color: ${ColorDark.textLight};
            }
          }
          &:hover {
            box-shadow: none;
            color: #000;
          }
        }
        .cybermonday-button {
          z-index: 10;
          top: 45px;
          ${breakpoints.phone} {
            display: none;
          }
        }
        .priceBefore,
        .price {
          display: none;
        }
      }
    }
  }

  .vf-heading-text,
  .vf3-conversations-footer,
  .viafoura .vf-comment-header__actions .vf-actions-authentication,
  .viafoura .comment-nav-right {
    display: none;
  }
  .viafoura {
    .vf-tray-count {
      display: none !important;
    }
    .vf-button {
      border-radius: 50px !important;
    }
    .vf-button.is-cta.is-accent-color:not([disabled]) span {
      color: ${Color.white} !important;
    }
    .vf-load-more
      .vf-button.is-cta.is-accent-color:not([disabled])
      span.vf-loader-button__content {
      color: ${Color.grey} !important;
    }
    .vf-load-more .vf-button.is-cta.is-inverted.is-accent-color:active span {
      color: ${Color.white} !important;
    }
    .vf3-comments .vf-tabbed-nav {
      border-bottom-color: ${Color.tertiaryColor}!important;
      height: 44px !important;
    }
    span {
      ${Font.sansRegular};
      color: ${Color.grey};
      font-size: 12px;
    }
    p span {
      font-size: 14px;
    }
    .vf-accent-border-colour {
      border-color: ${Color.mainColor};
      &::before {
        border-color: ${Color.mainColor};
      }
    }
    .vf-tabbed-nav .vf-nav-button {
      padding: 0;
      .vf-badge {
        background: url("/img/comments.svg") no-repeat;
        height: 32px;
        width: 32px;
        background-size: contain;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }

    svg g {
      color: ${Color.grey};
    }

    .comment-nav-right {
      .vf-button {
        margin-left: -5px;
        border-radius: 35px;
        height: 22px;
        width: 22px;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border: 1px solid #555555;
        svg {
          height: 12px;
          width: 12px;
        }
      }
    }
    .vf-load-more {
      .vf-button {
        width: max-content;
        border: 1px solid #555555;
        border-radius: 50px;
        height: 44px;
        padding: 0 2rem;
        &:hover {
          color: #555555 !important;
          border-color: #555555 !important;
        }
      }
    }
    .vf-content-actions {
      flex-wrap: wrap;
      ${breakpoints.phone} {
        .vf-horiz-list {
          margin-left: 0 !important;
        }
      }
    }
  }

  .viafoura .vf-content-focus-container__contents .vf-post-form__new-content {
    ${(props) => (!props.userData ? "display: none" : "")};
  }
`;

export const Login = styled.button`
  border-radius: 50px;
  display: flex;
  ${Font.sansBold};
  justify-content: center;
  white-space: nowrap;
  width: auto;
  height: 34px;
  border: 1px solid ${Color.black000};
  background: #fff;
  align-items: center;
  cursor: pointer;
  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      border: 1px solid ${ColorDark.textLight};
      background: none;
    }
  }
  p {
    font-size: 12px;
    color: ${Color.black000};
    padding: 0 2rem;
    display: flex;
    width: 100%;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
  }
  ${breakpoints.mobileFirst} {
    font-size: 14px;
    width: auto;
    height: 44px;
    padding: 0 2rem;
    align-items: center;
  }
`;
