import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const NormalNewsContainer = styled.section.attrs((props) => ({
  className: props.className,
}))`
  .normal-header {
    .image-container {
      margin: 40px 0 20px 0;
    }
  }
`;

export const StoryGrid = styled.section.attrs((props) => ({
  className: props.className,
}))`
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  grid-column-gap: 1rem;
  justify-content: right;
  width: 100%;

  ${breakpoints.phone} {
    grid-column-gap: 0;
    &.notaVideo {
      flex-direction: column;
    }
  }
  .date {
    ${Font.sansRegular};
    font-size: 11px;
    display: flex;
    flex-direction: row;
    color: ${Color.black000};
    white-space: nowrap;
    flex-wrap: wrap;
    line-height: 18px;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
  }
  .modificatedDate {
    margin-left: 3px;
    padding-bottom: 0.3rem;
  }
  .createDate {
    padding-bottom: 0.3rem;
  }
  a {
    text-decoration: none;
  }
`;

export const StoryBody = styled.article.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  flex-direction: column;
  width: calc(100% - 300px);
  max-width: 600px;
  margin: 0 auto;
  ${breakpoints.phone} {
    width: 100%;
  }
  .Especial,
  .especial {
    display: flex;
    justify-content: center;
    ${breakpoints.desktop} {
      min-width: 1256px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    ${breakpoints.tablet} {
      min-width: 956px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .Especial {
    picture {
      width: 100%;
    }
  }
  .StoryTextContainer {
    padding-bottom: 23px;
  }
  .sign-separator {
    h2 {
      font-size: 16px;
      line-height: 28px;
      ${breakpoints.phone} {
        font-size: 17px;
        line-height: 28px;
      }
    }
  }
  iframe {
    max-width: 600px;
    width: 100%;
    min-height: 300px;
    margin: 17px 0 !important;
  }

  .bannerCollapse iframe {
    min-height: 0;
    margin: 0px !important;
    width:inherit;
  }
  .trinity-player-iframe-wrapper {
    max-height: 120px;
    iframe {
      max-height: 120px;
      min-height: 120px;
    }
  }

  .twitter-tweet {
    padding: 17px 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bottom-story a {
    display: flex;
    &.with-image {
      flex-direction: column;
    }
  }
  .list-bottom-authors {
    .bottom-author {
      align-items: initial;
      padding: 1rem 0 40px 0;
      position: relative;
      min-height: 90px;
      ${Font.sansSemiBold};
      font-size: 16px;
      line-height: 21px;

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0px;
        width: 100%;
        height: 1px;
        background-color: #e0e0e0;
      }
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 2.8em;
        ${Font.sansLight};
        font-size: 17px;
        line-height: 22px;
        ${breakpoints.phone} {
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.15px;
        }
        a {
          border-bottom: 1px solid ${Color.mainColor};
          width: max-content;
          ${breakpoints.darkMode} {
            [data-theme="dark"] & {
              border-bottom: 1px solid ${ColorDark.mainColor};
            }
          }
        }
      }
    }
  }

  .related-container {
    div:not(:first-child) {
      .separator-title {
        display: none;
      }
    }
  }

  cite {
    display: flex;
    flex-direction: column;
    ${Font.clarinVarSub2Regular};
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.28px;
    font-style: normal;
    width: 70%;
    padding: 27px 0;

    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
    p {
      padding-bottom: 1rem;
      &:first-letter {
        padding-left: 15px;
      }
      &:before {
        content: "";
        background: url("/img/quotes.svg") no-repeat;
        height: 12px;
        width: 12px;
        position: absolute;
        background-size: cover;
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            filter: invert(79%) sepia(0%) saturate(1497%) hue-rotate(192deg)
              brightness(96%) contrast(78%);
          }
        }
      }
      &:after {
        content: "";
        background: url("/img/quotes.svg") no-repeat;
        height: 12px;
        width: 12px;
        position: absolute;
        background-size: cover;
        transform: rotateX(180deg) rotateY(180deg);
        margin-left: 6px;
        ${breakpoints.darkMode} {
          [data-theme="dark"] & {
            filter: invert(79%) sepia(0%) saturate(1497%) hue-rotate(192deg)
              brightness(96%) contrast(78%);
          }
        }
      }
    }
    .author-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: left;
      .athor-data {
        display: flex;
        flex-direction: column;
        ${Font.sansRegular};
        font-size: 16px;
        line-height: 21px;
        .author-name {
          ${Font.sansBold};
        }
      }
    }
    picture {
      min-width: 60px;
    }
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
`;

export const DataStory = styled.section.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1rem;
  width: 100%;
  column-gap: 1rem;
  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      border-bottom: 1px solid ${ColorDark.greySeparator};
    }
  }

  ${breakpoints.phone} {
    flex-direction: column;
    border-top: 1px solid #e0e0e0;
  }
  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      border-top: 1px solid ${ColorDark.greySeparator};
    }
  }

  .list-authors {
    ${breakpoints.phone} {
      align-self: start;
    }

    display: block;
    ${Font.sansSemiBold};
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0px;
    line-height: 21px;
    padding-right: 0.3rem;

    ${breakpoints.phone} {
      width: 100%;
      padding: 0.5rem 0;
    }
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        color: ${ColorDark.textLight};
      }
    }
  }

  .place-social-mobile {
    display: flex;
    align-items: center;

    ${breakpoints.phone} {
      width: 100%;
      border-top: 1px solid #e0e0e0;
    }
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        border-top: 0.5px solid ${ColorDark.greySeparator};
        button {
          border: 1px solid ${ColorDark.textLight}!important;
          svg,
          img {
            fill: ${ColorDark.textLight}!important;
          }
        }
      }
    }
  }

  .SocialMedia {
    border-top: none;
  }
`;
